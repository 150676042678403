.items-carousel-box{
    display: flex;
  //  width: 202px;
    height: 255px;
    padding: 12px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    border-radius: 5px;
    background: #FFF;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25);

    @media (max-width: 1280px) {
     //   width: 200px;
        height: 255px;
    }

    @media (max-width: 576px) {
   //     width: 192px;
        height: 200px;
    }

    @media (max-width: 390px) {
    //    width: 173px;
        height: 200px;
    }

    h2,span{
        color: #23346C;
        font-size: 16px;
        font-weight: 700;
        text-align: left;
        width: 100%;
        margin-bottom: 0px;
        height: 40px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        line-height: 1.2;
    }

    p{
        color: #333;
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        text-align: center;
        margin-bottom: 0px;
    }

    .items-carousel-box-img{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100px;

        @media (max-width: 576px) {
            height: 80px;
        }

        img{
            height: 100px;

            @media (max-width: 576px) {
                width: 80px;
                height: 80px;
            }
        }
    }

    .items-carousel-box-info{
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .items-carousel-box-tile{
            width: 100%;

            h3{
                color:  #333;
                font-size: 10px;
                font-style: normal;
                font-weight: 400;
                text-align: left;
                margin-bottom: 0px;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                /* autoprefixer: off */
                overflow: hidden;
            }
        }

        .items-carousel-box-price{
            width: 100%;
            p{
                color: #333;
                font-size: 10px;
                font-style: normal;
                font-weight: 400;
                text-align: left;
                span{
                    color: #333;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 800;
                    height: inherit;
                    overflow: inherit;
                    display: block
                }
            }
        }
    }

}

.price-boxitem{
    display: block;
    font-family: Roboto;
    font-style: normal;
    font-weight: 800;
}
.price-boxitem div{
    margin-top: 10px;
    line-height: 135.9%;
    letter-spacing: .36px;
    font-size: 8px;
    color: gray;

    @media (max-width: 576px) {
        margin-top: 2px;
    }
}
.price-boxitem span{
    color: #333;
    font-size: 16px !important;
    line-height: normal;
    height: inherit;
    display: block;
    overflow: inherit;
}

